var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-56", attrs: { id: "vip-rules" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "px-4 py-0", attrs: { cols: "12" } }, [
                _c("p", {
                  staticClass: "title--text",
                  domProps: { innerHTML: _vm._s(_vm.vipRules) }
                })
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }